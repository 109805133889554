import React from 'react'
import { Footer, Navbar } from './components'
import { Link  } from 'react-scroll';
import { useHistory } from "react-router-dom";

function LandingPageLayout({ children, withFooter, props }) {
    const history = useHistory();
    const handleNavigation = (routeType, actionLink) => {
        if (routeType == 'newTab') {
          window.open(actionLink, '_blank');
        } else {
          history.push(actionLink);
          handleMobileNav();
        }
    }

    const handleMobileNav = () => {
        const element = document.getElementsByClassName('side-menu__block')[0];
        element.classList.remove("active");
    }

    return (
        <React.Fragment>
            <div className='page-wrapper'>
                <Navbar />
                {children}
                <Footer />
            </div>
            <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
            <div class="side-menu__block">


        <div class="side-menu__block-overlay custom-cursor__overlay">
            <div class="cursor" style={{top: "365px", left: "982px"}}></div>
            <div class="cursor-follower" style={{top: "343px", left: "960px"}}></div>
        </div>
        <div class="side-menu__block-inner  mCustomScrollbar _mCS_1"><div id="mCSB_1" class="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside" style={{maxHeight: "none"}} tabindex="0"><div id="mCSB_1_container" class="mCSB_container" style={{position:"relative", top:"0", left:"0"}} dir="ltr">
            <div class="side-menu__top justify-content-end">
                <a href="#" class="side-menu__toggler side-menu__close-btn"><img src="assets/images/shapes/close-1-1.png" alt="" class="mCS_img_loaded" /></a>
            </div>

            <nav class="mobile-nav__container">
                        <ul class=" main-nav__navigation-box">
                            {/* <li class="current">
                                <a href="javascript:void(0)" onClick={() => handleNavigation('/')}>Home</a>
                            </li> */}
                            <li>
                                <Link 
                                    to="services-section" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-120} 
                                    duration={1000} 
                                    onClick={()=>handleMobileNav()}
                                >
                                    Our Services
                                </Link>
                            </li>
                            <li class="">
                                <Link 
                                    to="why-choose-section" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-120} 
                                    duration={1000} 
                                    onClick={() =>handleMobileNav()}
                                >
                                    Why Choose Us
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="about-section" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-120} 
                                    duration={1000} 
                                    onClick={() =>handleMobileNav()}
                                >
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="our-projects" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-120} 
                                    duration={1000} 
                                    onClick={()=>handleMobileNav()}
                                >
                                    Our Projects
                                </Link>
                            </li>
                            <li style={{color: "inherit"}}>
                                {/* <Link 
                                    to="career-section" 
                                    spy={true} 
                                    smooth={true} 
                                    offset={-120} 
                                    duration={1000} 
                                    onSetActive={handleMobileNav}
                                >
                                    Career
                                </Link> */}
                                <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'/career')} style={{color: "inherit"}}>Career</a>
                                <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'/ebook')} style={{color: "inherit"}}>Free Ebook</a>
                            </li>
                            <li style={{color: "inherit"}}>
                            <Link 
                                to="contact-section" 
                                spy={false} 
                                smooth={true} 
                                offset={40} 
                                onClick={() =>handleMobileNav()}
                                duration={1000} 
                            >
                                Contact Us
                            </Link>
                            </li>
                        </ul>
                    </nav>
            <div class="side-menu__sep"></div>
            <div class="side-menu__content">
                <p>Akra is a leading provider of IT solutions, specializing in business solutions. We are committed to providing our clients with the highest level of support and satisfaction.</p>
                <p><a href="mailto:contact@akrateq.com">contact@akrateq.com</a> <br /> <a href="tel:8097048449">8097048449</a></p>
                <div class="side-menu__social">
                    {/* <a class="fab fa-facebook-f" href="#"></a> */}
                    {/* <a class="fab fa-twitter" href="#"></a> */}
                    {/* <a class="fa-brands fa-x-twitter" href = "#"></a> */}
                    {/* <a class="fab fa-instagram" href="#"></a> */}
                    <a href="https://www.linkedin.com/company/akra-teq/" target="_blank" rel="noreferrer"><i class="fab fa-linkedin-in"></i></a>
                    {/* <a class="fab fa-pinterest-p" href="#"></a> */}
                </div>
            </div>
        </div><div id="mCSB_1_scrollbar_vertical" class="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical" style={{display: "block"}}><div class="mCSB_draggerContainer"><div id="mCSB_1_dragger_vertical" class="mCSB_dragger" style={{position: "absolute", minHeight: "30px", display: "block", height: "39px", maxHeight: "175px", top: "0px"}}><div class="mCSB_dragger_bar" style={{lineHeight: "30px;"}}></div></div><div class="mCSB_draggerRail"></div></div></div></div></div>
    </div>
    </React.Fragment>
    )
}

export default LandingPageLayout
