import React from 'react'
import { withRouter } from "react-router";
import bannerImage from '../../../../../../assets/images/backgrounds/banner-bg-1-1.png';
import bannerImage1 from '../../../../../../assets/resources/banner-1-1.png';
import { Element  } from 'react-scroll';

function Hero(props){

    const handleNavigation = (routeType, actionLink) => {
        if (routeType == 'newTab') {
          window.open(actionLink, '_blank');
        } else {
          props.history.push(actionLink);
        }
    }

    return (
        <Element name="home">
        <section class="banner-one" style={{top: "-1px",marginLeft: "-2px", backgroundImage: `url(${bannerImage})`}}>
            <img src={bannerImage1} alt="" class="banner-one__moc" />
            <div class="container-fluid">
                <div class="">
                    <div class="col-lg-6">
                        <div class="banner-one__content">
                            <h3><span>Your </span> <div>Trusted  IT Partner </div></h3>
                            <p>We provide comprehensive IT solutions to help businesses thrive in the digital age</p>
                            <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'/ebook')} class="thm-btn banner-one__btn">Get Your Free Ebook <i class="fa fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </Element>
    )
}

export default withRouter(Hero);
