import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';

const Contact = (props) => {

    return (
        <div class="page-wrapper">
        <section class="page-header" style={{backgroundImage: "url(assets/images/backgrounds/page-header-bg-1-1.jpg);"}}>
            <div class="container mt-5">
                <h2>Contact Us</h2>
                <ul class="list-unstyled thm-breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li><span>Contact Us</span></li>
                </ul>
            </div>
        </section>

        <section class="contact-two">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="contact-two__content">
                            <div class="contact-two__block">
                                <h3>Don't Hesitate To Contact Us</h3>
                                <p>Sed ut perspiciatis unde omnis iste natus erro voluptat accusantium doloremque laudantium
                                    totam rem aperiam eaque</p>
                            </div>
                            <ul class="list-unstyled contact-two__info-list">
                                <li>
                                    <div class="contact-two__info-list-title">
                                        <i class="far fa-map-marked"></i>
                                        Address :
                                    </div>
                                    <p>27 Division St, New York, <br />NY 10002,USA</p>
                                </li>
                                <li>
                                    <div class="contact-two__info-list-title">
                                        <i class="far fa-phone"></i>
                                        Call Us :
                                    </div>
                                    <p><a href="tel:08964712365">08964712365</a></p>
                                </li>
                                <li>
                                    <div class="contact-two__info-list-title">
                                        <i class="far fa-envelope"></i>
                                        Email Us :
                                    </div>
                                    <p><a href="mailto:support@gmail.com">support@gmail.com</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <form action="inc/sendemail.php" class="contact-form-validated contact-two__form">
                            <div class="contact-two__block">
                                <h3>Get In Touch</h3>
                                <p>Sed ut perspiciatis unde omnis iste natus erro voluptat accusantium <br />doloremque laudantium
                                    totam rem aperiam eaque</p>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <input type="text" name="name" placeholder="Full Name" />
                                </div>
                                <div class="col-md-6">
                                    <input type="text" name="email" placeholder="Email Address" />
                                </div>
                                <div class="col-md-12">
                                    <input type="text" name="subject" placeholder="Subject" />
                                </div>
                                <div class="col-md-12">
                                    <textarea name="message" placeholder="Message"></textarea>
                                    <button type="submit" class="thm-btn contact-two__btn">Send Message <i class="fa fa-angle-double-right"></i></button>
                                </div>
                            </div>
                        </form>
                        <div class="result"></div>
                    </div>
                </div>
            </div>
        </section>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd" class="google-map__contact" allowfullscreen></iframe> */}
        </div>
    )
}

export default withRouter(Contact);
