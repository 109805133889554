import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router";
import { Link, Element  } from 'react-scroll';
import { BASEURL } from '../../../../../../constant/constant';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function WhyChooseUs(){
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading,setLoading] = useState()
    const [contactUs, setContactUs] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        clientCompanyName: "",
        textmessage: "",
    });
    const validate = (values) => {
        const errors = {};
        
        setIsSubmitted(true);
        if (!values.name) {
          errors.name = 'Name field is required';
        }

        if (!values.clientCompanyName) {
            errors.clientCompanyName = 'Company field is required';
        }

        if (!values.textmessage) {
            errors.textmessage = 'Message field is required';
        }

        if (!values.mobileNumber) {
            errors.mobileNumber = 'Phone number field is required';
        }else if(!/^[0-9]{10}$/i.test(values.mobileNumber)){
            errors.mobileNumber = 'Invalid phone number';
        }

        if (!values.email) {
            errors.email = 'Email field is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    };

    const handleSubmit = (data) =>{
        setContactUs(data)
        handleAddCountactUs(data)
    }

    const handleAddCountactUs = (contactData) => {
    setLoading(true);   
    fetch(`${BASEURL}/contactUsEmail`, {
        method: "POST",
        headers: {
        "Content-Type": "application/json"
        },
        body: JSON.stringify(contactData),
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.success) {
                setLoading(false);
                toast('Thankyou for submiting',{
                    hideProgressBar: true
                });
                setContactUs({
                    name: "",
                    email: "",
                    mobileNumber: "",
                    clientCompanyName: "",
                    textmessage: "",
                })
            }else{
                setLoading(false);   
            }
        })
        .catch((error) => {
            setLoading(false)
            console.error("Error:", error);
            alert("Something Went Wrong");
        });
    };

    return (
        <Element name="contact-section">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <ToastContainer />
            <section class=" mb-5 pb-5 mt-4">
                <div class="container">
                    <div class="block-title text-center">
                        <p><span>Contact Us</span></p>
                        <h3>Want to discuss your IT needs?</h3>
                        <p className='text-muted mt-2'>We're here to help! Contact us today and let us know how we can assist you.</p>
                        <div class="block-title__line"></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="contact-one__box" style={{backgroundImage: "url(assets/images/resources/contact-1-1.png)"}}>
                                <div class="contact-one__box-inner">
                                    <h3>Don't wait!</h3>
                                    <p>Contact us today and let us help you unlock the power of technology.</p>
                                    <span className='text-white pl-1 font-weight-bold '>Or Call Us Directly at:</span>
                                    <a href="tel:8097048449" class="thm-btn contact-one__box-btn mt-2"><i class="far fa-phone"></i><span className='mx-4'>8097048449 </span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 d-flex">
                            <div class="my-auto">
                                <div action="inc/sendemail.php" class="contact-form-validated contact-one__form">
                                    <h3>Fill out the form below and we'll be in touch shortly.</h3>
                                    <Formik
                                        initialValues={contactUs}
                                        validate={validate}
                                        onSubmit={handleSubmit}
                                        validateOnBlur={false}
                                        validateOnChange={true}
                                        >
                                    {({ errors, touched }) => (
                                    <Form>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i class="contact-one__input-icon far fa-user"></i>
                                                    <Field type="text" placeholder="Your Full Name" name="name" className={errors.name && touched.name && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="name" component="div" className="error" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i class="contact-one__input-icon far fa-envelope"></i>
                                                    <Field type="email" placeholder="Your Email" name="email" className={errors.email && touched.email && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="email" component="div" className="error" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i class="contact-one__input-icon far fa-book"></i>
                                                    <Field type="text" placeholder="Your Company" name="clientCompanyName" className={errors.clientCompanyName && touched.clientCompanyName && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="clientCompanyName" component="div" className="error" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i class="contact-one__input-icon far fa-phone"></i>
                                                    <Field type="number" placeholder="Your Phone" name="mobileNumber" className={errors.mobileNumber && touched.mobileNumber && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="mobileNumber" component="div" className="error" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="contact-one__input-group">
                                                    <i class="contact-one__input-icon far fa-pencil-alt"></i>
                                                    <Field as="textarea" type="text" placeholder="Write Message" name="textmessage" className={errors.textmessage && touched.textmessage && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="textmessage" component="div" className="error" />
                                                </div>
                                            </div>
                                            {/* <div class="col-lg-12" style={{zIndex: '99999'}}> */}
                                            <div class="col-lg-12" style={{zIndex: '1'}}>
                                                <button type='submit' class="thm-btn mt-2 text-white" style={{cursor:'pointer'}}>{loading ? 'sending...': 'Send Message'} <i class="fa fa-angle-double-right"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                    )}
                                    </Formik>
                                </div>
                                <div class="result"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    )
}

export default withRouter(WhyChooseUs);
