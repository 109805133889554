import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';

const About = (props) => {

    return (
        <div class="page-wrapper">
        <section class="page-header" style={{backgroundImage: "url(assets/images/backgrounds/page-header-bg-1-1.jpg);"}}>
            <div class="container mt-5">
                <h2>About Us</h2>
                <ul class="list-unstyled thm-breadcrumb">
                    <li><a href="#">Home</a></li>
                    <li><span>About Us</span></li>
                </ul>
            </div>
        </section>

        <section class="service-two service-two__service-page service-two__about-page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="service-two__image">
                            <img src="assets/images/resources/about-page-moc-1.png" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="service-two__block">
                            <div class="block-title-two text-left">
                                <p>about us</p>
                                <h3>Exclusive Agency To <br /> Provide Soluation</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tem
                                por incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                            <ul class="list-unstyled video-one__list">
                                <li>
                                    <i class="far fa-check"></i>
                                    Smashing Book 6 Is Here New Frontiers In Web Design
                                </li>
                                <li>
                                    <i class="far fa-check"></i>
                                    Introduction To Animation And The iMessage App Store With Shruggie
                                </li>
                                <li>
                                    <i class="far fa-check"></i>
                                    Get Your Mobile Site Ready For The 2018 Holiday ways goods
                                </li>
                                <li>
                                    <i class="far fa-check"></i>
                                    Making Distributed Product Teams Work More Efficiently other
                                </li>
                            </ul>

                            <a href="#" class="thm-btn">Read More <i class="fa fa-angle-double-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="funfact-one funfact-one__home-two funfact-one__about-page">
            <div class="container">
                <div class="row high-gutter">
                    <div class="col-lg-3 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                <i class="far fa-user-alt"></i>
                            </div>
                            <h3><span class="counter">2500</span>+</h3>
                            <p>Project</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                <i class="far fa-heart"></i>
                            </div>
                            <h3><span class="counter">2960</span>+</h3>
                            <p>Satisfied Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                <i class="far fa-cloud-download"></i>
                            </div>
                            <h3><span class="counter">5620</span>
                            </h3>
                            <p>Win Awards</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                <i class="far fa-star"></i>
                            </div>
                            <h3><span class="counter">365</span>+</h3>
                            <p>Team Member</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="cta-two cta-two__about-page" style={{backgroundImage: "url(assets/images/backgrounds/cta-bg-1-1.jpg);"}}>
            <div class="container text-center">
                <h3>Our Business Advisors <br />
                    Always Help You</h3>
                <a href="#" class="thm-btn">Read More <i class="fa fa-angle-double-right"></i></a>
            </div>
        </section>
    </div>
    )
}

export default withRouter(About)
