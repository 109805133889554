import React from 'react';

const Text = ({ text, styles, className }) => {
    return (
        <React.Fragment>
            <p
                className={className}
                style={{ ...styles, fontFamily: 'Poppins' }}
            > { text}</p>
        </React.Fragment>
    )
}

export default Text;