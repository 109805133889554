import React from 'react'
import { withRouter } from "react-router";
import { Hero, Services, WhyChooseUs, AboutUs, ContactUs, Career, OurProjects } from './components'

function Home(props) {
    
    return (
        <React.Fragment>
            <Hero />
            <Services />
            <WhyChooseUs />
            <AboutUs />
            <OurProjects />
            {/* <Career /> */}
            <ContactUs />
        </React.Fragment>
    )
}

export default withRouter(Home);

