import React from 'react'
import { Popover } from '@headlessui/react'
import { Text } from '../../components';
import { withRouter } from "react-router";

const Navbar = (props) => {

    const handleNavigation = (routeType, actionLink) => {
        if (routeType == 'newTab') {
            window.open(actionLink, '_blank');
        } else {
            props.history.push(actionLink);
        }
    }
    return (
        <div>
            <Popover className="fixed bg-white z-40 w-full border-b">
                <div className="max-w-full px-6 mx-auto">
                    <div className="flex justify-between items-center  border-gray-100 py-4 md:justify-start md:space-x-10">
                        <div className="flex w-full justify-center sm:justify-start lg:w-0 lg:flex-1">
                            <div className='cursor-pointer' onClick={() => handleNavigation(null, '/')}>
                                <Text
                                    className="primary-text-color  text-xl"
                                    text={'Aptify'}
                                />
                            </div>
                        </div>
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <p className='text-md font-semibold'>{props.screenName || ""}</p>
                            <a
                                href="#"
                                onClick={() => handleNavigation(null, '/sign-in')}
                                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-red-500 rounded-full shadow-sm text-xs font-medium text-red-600 bg-white hover:bg-red-400 hover:text-white"
                            >
                                <div className='flex flex-row justify-between items-center space-x-2'>
                                    <Text
                                        className=""
                                        text={'Logout'}
                                    />
                                    <p>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                        </svg>

                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )
}
export default withRouter(Navbar)