import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import {animateScroll} from 'react-scroll'

const SERVICES = [

    {
        id:1,
        title:"Web Development",
        description:`Get a captivating website that mirrors your brand and engages your audience, with comprehensive development services.`,
        icon:"far fa-laptop-code"
    },
    {
        id:2,
        title:"Cloud Solutions",
        description:`Utilize cloud computing's scalability, agility, and cost-effectiveness to overhaul IT infrastructure, offering comprehensive cloud solutions.
        `,
        icon:"far fa-cloud"

    },
    {
        id:3,
        title:"Cybersecurity",
        description:`Protect your valuable data and systems from cyber threats with our robust cybersecurity solutions. We provide comprehensive security assessments, vulnerability scanning, penetration testing, and incident response services to keep your organization safe.`,
        icon:"fas fa-shield-alt"

    },
    {
        id:4,
        title:"Software Development",
        description:`Build custom software applications that automate tasks, improve efficiency, and give you a competitive edge. Our software development expertise spans across various industries and technologies.
        `,
        icon:"fas fa-code"

    },
    {
        id:5,
        title:"IT Consultancy",
        description:`Get expert guidance and advice on all your IT challenges. Our IT consultants can help you develop a strategic IT roadmap, optimize your IT infrastructure, and make informed technology decisions.
        `,
        icon:"fas fa-cogs   "

    },
    {
        id:6,
        title:"Network Solutions",
        description:`Design, implement, and manage a reliable and secure network that supports your business operations. We offer a wide range of network solutions, including network design, network security, and network monitoring.
        `,
        icon:"fas fa-network-wired"

    },
    {
        id:7,
        title:"Data Analytics",
        description:`Gain valuable insights from your data to make informed business decisions. Our data analytics services include data warehousing, business intelligence, and data visualization.
        `,
        icon:"fas fa-chart-line"

    },
    {
        id:8,
        title:"E-commerce Solutions",
        description:`Create a seamless and engaging online shopping experience for your customers. We offer comprehensive e-commerce solutions, including e-commerce website development, payment processing, and marketing automation.
        `,
        icon:"fas fa-shopping-cart"

    },
    {
        id:9,
        title:"Mobile App Development",
        description:`Create engaging mobile apps that drive growth, leveraging expertise in native and hybrid app development.
        `,
        icon:"fas fa-mobile-alt"

    },
]
const Services = (props) => {

    const handleNavigation = (routeType, actionLink) => {
        if (routeType == 'newTab') {
            window.open(actionLink, '_blank');
        } else {
            props.history.push(actionLink);
        }
    }

    useEffect(() => {
        // Scroll to the top of the page on first rendering
        animateScroll.scrollToTop();
      }, []);

  

    return (
        <div class="page-wrapper">
        <section class="cta-eleven">
            <img src="assets/images/shapes/cta-shape-2-1.png" class="cta-eleven__moc-1" alt="" />
            <img src="assets/images/shapes/cta-shape-2-2.png" class="cta-eleven__moc-2" alt="" />
            <img src="assets/images/shapes/cta-shape-2-3.png" class="cta-eleven__moc-3" alt="" />
            <div class="container ">
                <div>
                    <h2 className='text-white'>Our Services</h2>
                    <ul class="list-unstyled thm-breadcrumb">
                        <li><a  href="javascript:void(0)" onClick={()=>handleNavigation(null,'/')}>Home</a></li>
                        <li><span>Our Services</span></li>
                    </ul>
                </div>
            </div>
        </section>
        <section class="service-two service-two__service-page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="">
                    <div class="block-title text-center">
                        <p><span>Our Services</span></p>
                        {/* <h3>Empowering Your Business <br/> Through Technology</h3> */}
                        <h3>We Provide Creative Soluation For You</h3>
                        <div class="block-title__line"></div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        {/* <section class="cta-eleven">
            <img src="assets/images/shapes/cta-shape-2-1.png" class="cta-eleven__moc-1" alt="" />
            <img src="assets/images/shapes/cta-shape-2-2.png" class="cta-eleven__moc-2" alt="" />
            <img src="assets/images/shapes/cta-shape-2-3.png" class="cta-eleven__moc-3" alt="" />
            <div class="container">
                <h3>Work With Us</h3>
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque lauda <br /> ntium totam
                    rem aperiam eaque ipsa quae ab illo inventore veritatis et </p>
                <a href="#" class="thm-btn cta-eleven__btn">Read More <i class="fa fa-angle-double-right"></i></a>
            </div>
        </section> */}

        <section class="service-eight">
            <div class="container">
                {/* <div class="block-title-two text-center">
                    <p>our services</p>
                    <h3>We Provide Creative <br /> Soluation For You</h3>
                </div> */}
                {SERVICES?.map((service)=>(
                    <div class="service-eight__single" key={service?.id}>
                        <div class="service-eight__icon">
                            <i className= {service?.icon} />
                        </div>
                        <div class="service-eight__content">
                            {/* <h3><a href="#">{service?.title}</a></h3> */}
                            <h3>{service?.title}</h3>
                            <p>{service?.description}</p>
                        </div>
                        {/* <div class="service-eight__btn-block">
                            <a href="#" class="thm-btn service-eight__btn">Read More <i class="fa fa-angle-double-right"></i></a>
                        </div> */}
                    </div>
                    ))
                }
              
            </div>
        </section>
    </div>
    )
}

export default withRouter(Services);
