import React from 'react'
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';
import { Link, Element  } from 'react-scroll';

function Services(){

    const history = useHistory()

    const handleNavigation = (routeType, actionLink) => {
        if (routeType == 'newTab') {
            window.open(actionLink, '_blank');
        } else {
            history.push(actionLink);
        }
    }
    return (
        <Element name="services-section">
            <section class="about-two mt-5">
                <img src="assets/images/shapes/about-two-bg.png" alt="" class="about-two__bg" />
                <div class="container">
                    <img src="assets/images/resources/cta-2-1.png" alt="" class="about-two__moc hideInMobile" />
                    <div class="row">
                        <div class="col-lg-7">
                            <div class="about-two__content">
                                <div class="block-title text-left">
                                    <p><span>Our Services</span></p>
                    <img src="assets/images/resources/cta-2-1.png" alt="" class="about-two__moc hideInLarge " />

                                    <h3>Empowering Your Business <br/> Through Technology</h3>
                                    <div class="block-title__line"></div>
                                </div>
                                {/* <h4 style={{marginBottom:'14px'}}>Our Core Service Offerings:</h4> */}

                                <div class="about-two__box">
                                    <div class="about-two__box-icon">
                                        <i class="far fa-laptop-code"></i>
                                    </div>
                                    <div class="about-two__box-content">
                                        <h3>Web Development:</h3>
                                        <p>Get a captivating website that mirrors your brand and engages your audience, with comprehensive development services.</p>
                                    </div>
                                </div>
                                <div class="about-two__box">
                                    <div class="about-two__box-icon">
                                        <i class="fas fa-mobile-alt"></i>
                                    </div>
                                    <div class="about-two__box-content">
                                        <h3>Mobile App Development:</h3>
                                        <p>Create engaging mobile apps that drive growth, leveraging expertise in native and hybrid app development.</p>
                                    </div>
                                </div>
                                <div class="about-two__box">
                                    <div class="about-two__box-icon">
                                        <i class="far fa-cloud"></i>
                                    </div>
                                    <div class="about-two__box-content">
                                        <h3>Cloud Solutions:</h3>
                                        <p>Utilize cloud computing's scalability, agility, and cost-effectiveness to overhaul IT infrastructure, offering comprehensive cloud solutions.
                                        </p>
                                    </div>
                                </div>


                                <p  class="thm-btn about-one__btn cursor-pointer mt-5" style={{cursor: 'pointer'}} 
                                 onClick={() => handleNavigation(null, '/services')}

                                >View More <i class="fa fa-angle-double-right"></i></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    )
}

export default withRouter(Services);
