import { useEffect,useState } from 'react';
import { Text } from '../../../../components';
import { withRouter } from "react-router";
import { Link  } from 'react-scroll';

const Navbar = (props) => {

  const [isHomeView, setHomeVIew] = useState(true);

  useEffect(()=>{
    if (props.location.pathname !== '/') {
      setHomeVIew(false);
    }else{
      setHomeVIew(true);
    }
    console.log('navbar',props);
  },[props])

  const handleNavigation = (routeType, actionLink) => {
    if (routeType == 'newTab') {
      window.open(actionLink, '_blank');
    } else {
      props.history.push(actionLink);
    }
  }
  return (
    <header className="site-header-one">
      <nav id='web-navbar' class="main-nav__one stricky" style={!isHomeView? {display: 'none'}:{}}>
          <div class="container-fluid">
              <div class="main-nav__logo-box" style={{cursor: 'pointer'}}>

              <Link 
                            to="home" 
                            spy={false} 
                            smooth={true} 
                            offset={-120} 
                            duration={1000} 
                        >
                            
                  {/* <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'/')}>
                      <img src="assets/images/logo-full-light.png" alt="" />
                  </a> */}
                      <img src="assets/images/logo-full-light.png" style={{width: '85px'}} alt="" />
                        </Link>
                  <a href="#" class="side-menu__toggler"><i class="fa fa-bars"></i></a>
              </div>
              <div class="main-nav__main-navigation">
                  <ul class=" main-nav__navigation-box">
                      {/* <li>
                        <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'/')}>Home</a>
                      </li> */}
                      {/* <li style={{cursor: 'pointer'}}>
                        <Link 
                            to="home" 
                            spy={false} 
                            smooth={true} 
                            offset={-120} 
                            duration={1000} 
                        >
                            Home
                        </Link>
                      </li> */}
                      <li style={{cursor: 'pointer'}}>
                        <Link 
                            to="services-section" 
                            spy={false} 
                            smooth={true} 
                            offset={-120} 
                            duration={1000} 
                        >
                            Our Services
                        </Link>
                      </li>
                      <li style={{cursor: 'pointer'}}>
                        <Link 
                            to="why-choose-section" 
                            spy={false} 
                            smooth={true} 
                            offset={-120} 
                            duration={1000} 
                        >
                            Why Choose Us
                        </Link>
                          {/* <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'about')}>About</a> */}
                      </li>
                      <li style={{cursor: 'pointer'}}>
                        <Link 
                            to="about-section" 
                            spy={false} 
                            smooth={true} 
                            offset={-120} 
                            duration={1000} 
                        >
                            About Us
                        </Link>
                          {/* <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'about')}>About</a> */}
                      </li>
                      <li style={{cursor: 'pointer'}}>
                        <Link 
                            to="our-projects" 
                            spy={true} 
                            smooth={true} 
                            offset={-120} 
                            duration={1000} 
                        >
                            Our Projects
                        </Link>
                      </li>
                        <li style={{cursor: 'pointer',color: "inherit"}}>
                          <a href="javascript:void(0)" className='currentLink' onClick={()=>handleNavigation(null,'/career')} style={{color: "white"}}>Career</a>
                            {/* <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'about')}>About</a> */}
                        </li>
                        <li style={{cursor: 'pointer',color: "inherit"}}>
                          <a href="javascript:void(0)" className='currentLink' onClick={()=>handleNavigation(null,'/ebook')} style={{color: "white"}}>Free Ebook</a>
                            {/* <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'about')}>About</a> */}
                        </li>
                  </ul>
              </div>
              <div class="main-nav__right" style={{cursor: 'pointer'}}>
                <Link 
                    class="thm-btn"
                    to="contact-section" 
                    spy={false} 
                    smooth={true} 
                    offset={40} 
                    duration={1000} 
                >
                    Contact Us
                </Link>
                  {/* <a href="javascript:void(0)" onClick={()=>handleNavigation(null,'contact')} class="thm-btn">Contact Us <i class="fa fa-angle-right"></i></a> */}
              </div>
          </div>
      </nav>
    </header>
  )
}

export default withRouter(Navbar);


