import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Home } from "./components";

const LandingPage = (props) => {

  const handleNav = () => {
    props.history.push('Get-Started-Form');
  }

  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  );
};

export default withRouter(LandingPage);
