import React from 'react'
import { withRouter } from "react-router";
import { Link, Element  } from 'react-scroll';

function WhyChooseUs(){
    return (
        <Element name="why-choose-section">
            <section class="service-one">
                <div class="container">
                    <div class="block-title text-center">
                        <p><span>Why Choose  Us</span></p>
                        <h3>Why Choose Akra for Your IT Needs?</h3>
                        <div className='mt-4' style={{color: "#7e8aa7", fontWeight: 'bold'}}>In today's digital landscape, having a reliable and capable IT partner is no longer optional, it's essential. But with so many options available, why choose Akra? Here are just a few reasons why we stand out from the crowd:</div>
                        <div class="block-title__line"></div>
                    </div>
                    <div class="row high-gutter">
                        <div class="col-lg-4">
                            <div class="service-one__single">
                                <div class="service-one__top">
                                    <div class="service-one__icon">
                                        {/* <img src="assets/images/shapes/service-i-1.png" alt="" /> */}
                                        {/* <i class="fas fa-check-circle"></i> */}
                                        <i class="fas fa-medal fa-5x" style={{color:'var(--thm-base)'}}></i>
                                    </div>
                                    <div class="service-one__top-content">
                                        <h3><a href="#">Proven Expertise and Track Record:  </a></h3>
                                        {/* <p>Improve Business</p> */}
                                    </div>
                                </div>
                                <p>Our team comprises certified professionals with vast experience delivering successful IT solutions across diverse industries.</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="service-one__single">
                                <div class="service-one__top">
                                    <div class="service-one__icon">
                                        {/* <img src="assets/images/shapes/service-i-2.png" alt="" /> */}
                                        <i class="fas fa-handshake fa-5x" style={{color:'var(--thm-base)'}}></i>
                                    </div>
                                    <div class="service-one__top-content">
                                        <h3><a href="#">Client-Centric Approach:</a></h3>
                                        {/* <p>SEO Optimization</p> */}
                                    </div>
                                </div>
                                <p>Custom solutions tailored to your specific needs. We prioritize open communication and collaboration to ensure seamless integration.</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="service-one__single">
                                <div class="service-one__top">
                                    <div class="service-one__icon">
                                        {/* <img src="assets/images/shapes/service-i-3.png" alt="" /> */}
                                        <i class="fas fa-cogs  fa-5x" style={{color:'var(--thm-base)'}}></i>
                                    </div>
                                    <div class="service-one__top-content">
                                        <h3><a href="#">Scalable and Flexible Solutions:</a></h3>
                                        {/* <p>Web Soluations</p> */}
                                    </div>
                                </div>
                                <p> We cater to diverse needs, from startups to enterprises, offering scalable solutions tailored to budgets and priorities.</p>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <div class="col-lg-4 mt-5 mx-5">
                                <div class="service-one__single">
                                    <div class="service-one__top">
                                        <div class="service-one__icon">
                                            {/* <img src="assets/images/shapes/service-i-3.png" alt="" /> */}
                                            <i class="fas fa-shield-alt  fa-5x" style={{color:'var(--thm-base)'}}></i>

                                        </div>
                                        <div class="service-one__top-content">
                                            <h3><a href="#">Unwavering Commitment to Quality:</a></h3>
                                            {/* <p>Web Soluations</p> */}
                                        </div>
                                    </div>
                                    <p>We're committed to delivering high-quality solutions, exceeding expectations, with rigorous quality assurance processes for optimal results.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-5 mx-5 ">
                                <div class="service-one__single">
                                    <div class="service-one__top">
                                        <div class="service-one__icon">
                                            {/* <img src="assets/images/shapes/service-i-3.png" alt="" /> */}
                                            <i class="fas fa-shield-check  fa-5x" style={{color:'var(--thm-base)'}}></i>

                                        </div>
                                        <div class="service-one__top-content">
                                            <h3><a href="#">24/7 Support and Peace of Mind:</a></h3>
                                            {/* <p>Web Soluations</p> */}
                                        </div>
                                    </div>
                                    <p> We ensure your success with round-the-clock support, proactive monitoring, and maintenance, enabling focus on business operations.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div class="col-lg-4 mt-5">
                            <div class="service-one__single">
                                <div class="service-one__top">
                                    <div class="service-one__icon">
                                        <i class="fas fa-rocket  fa-5x text-primary"></i>

                                    </div>
                                    <div class="service-one__top-content">
                                        <h3><a href="#">Ready to Experience the Akra Difference?</a></h3>
                                    </div>
                                </div>
                                <p>Experience the Akra difference with a free consultation. Let us help achieve your IT goals confidently.</p>
                            </div>
                        </div> */}
                    </div>

                    {/* <div className='row justify-content-center mt-4'>
                        <p>Experience the Akra difference with a free consultation. Let us help achieve your IT goals confidently.</p>
                    </div> */}
                </div>
            </section>
        </Element>
    )
}

export default withRouter(WhyChooseUs);
