import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router";
import { Link, Element  } from 'react-scroll';
import { BASEURL } from '../../../../../../constant/constant';

function OurProjects(){

    const [projectData, setProjects] = useState([]);

    useEffect(()=>{
        fetchProjects();
    },[])

    const fetchProjects = () => {
        fetch(`${BASEURL}/clinetProjectListGet`)
        .then((response) => response.json())
        .then((res) => {
            if (res.succes) {
                let obj = [];
                res.project.forEach((el)=>{
                    obj.push({
                        'projectName': el.projectName,
                        'projectImgUrl': el.projectImageUpload[0].data
                    });
                })
                setProjects(obj);
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }

    return (
        <Element name="our-projects">
            <section class="portfolio-one">
            <div class="container">
                <div class="block-title text-center">
                    <p><span>Our Project</span></p>
                    <h3>Let’s See Our Popular <br /> Project</h3>
                    <div class="block-title__line"></div>
                </div>
                <div class="row">
                    {
                        projectData.map((el)=>{
                            return <div class="col-lg-4">
                                <div class="portfolio-one__single">
                                    <img src={el.projectImgUrl} alt="" style={{borderRadius: '1rem'}} />
                                    <h3>
                                        <a className='mt-3' href="javascript:void(0)" style={{fontSize: '1.2rem',display: 'flex',justifyContent: 'center'}}>
                                            {el.projectName}
                                        </a>
                                    </h3>
                                    {/* <div class="portfolio-one__content">
                                        <div class="portfolio-one__content-inner">
                                            <h3><a href="portfolio-details.html">{el.projectName}</a></h3>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </section>
        </Element>
    )
}

export default withRouter(OurProjects);
