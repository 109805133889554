import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import handleNavigation from '../../atom/Navigation';
import {animateScroll} from 'react-scroll'
import { BASEURL } from '../../constant/constant';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import img from ''
const Career = (props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const validate = (values) => {
        const errors = {};
        
        setIsSubmitted(true);
        if (!values.name) {
          errors.name = 'Name field is required';
        }

        if (!values.JobRoleId) {
            errors.JobRoleId = 'Job role field is required';
        }

        if (!values.experience) {
            errors.experience = 'Experience field is required';
        }

        if (!values.currentCTC) {
            errors.currentCTC = 'Current ctc field is required';
        }

        if (!values.expectedCTC) {
            errors.expectedCTC = 'Expected ctc field is required';
        }

        if (!values.noticePeriod) {
            errors.noticePeriod = 'Notice period field is required';
        }

        if (!values.isWillingToRelocate) {
            errors.isWillingToRelocate = 'Willing to relocate field is required';
        }
        
        if (!values.mobileNumber) {
            errors.mobileNumber = 'Phone number field is required';
        }else if(!/^[0-9]{10}$/i.test(values.mobileNumber)){
            errors.mobileNumber = 'Invalid phone number';
        }
        
        if (!values.email) {
            errors.email = 'Email field is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        if (!fileName && !fileContent) {
            errors.CvPdf = 'Resume field is required';
        }
        return errors;
    };

    const handleSubmit = (data) =>{
        setAddCareerData(data);
        handleAddCareerData(data);
    }
    const [addCareerData, setAddCareerData] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        CvPdf: null,
        JobRoleId: "",
        experience: "",
        currentCTC: "",
        expectedCTC: "",
        noticePeriod:"",
        isWillingToRelocate:"",
      });

      const [jobRolls,setJobRolls] = useState([])
      const [fileName, setFileName] = useState('');
      const [fileContent, setFileContent] = useState(null);
      const [loading,setLoading] = useState(false)


      const handleChangeAddCareerData = (e) => {
        const { name, value } = e.target;
    
        if (name === "CvPdf") {
            const file = e.target.files[0];

            setFileContent(file);
    
            setAddCareerData({
                ...addCareerData,
                CvPdf: file,
            });
        } else if (name === "mobileNumber") {
            const numericValue = value.replace(/\D/g, ''); 
            const limitedValue = numericValue.slice(0, 10);
            setAddCareerData({
                ...addCareerData,
                [name]: limitedValue,   
            });
        } else {
            setAddCareerData({
                ...addCareerData,
                [name]: value,
            }); 
        }
        };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
    
        // Create a synthetic event object to mimic a regular input event
        const syntheticEvent = {
            target: {
                name: 'CvPdf',
                files: [file]
            }
        };
    
        // Pass the synthetic event object to handleChangeAddCareerData
        handleChangeAddCareerData(syntheticEvent);
    };
    
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setFileName(file.name);
    
        // Create a synthetic event object to mimic a regular input event
        const syntheticEvent = {
            target: {
                name: 'CvPdf',
                files: [file]
            }
        };
    
        // Pass the synthetic event object to handleChangeAddCareerData
        handleChangeAddCareerData(syntheticEvent);
    };
    
      const handleAddCareerData = (data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", data?.name);
        formData.append("email", data?.email);
        formData.append("mobileNumber", data?.mobileNumber);
        formData.append("JobRoleId", data?.JobRoleId);
        formData.append("experience", data?.experience);
        formData.append("currentCTC", data?.currentCTC);
        formData.append("expectedCTC", data?.expectedCTC);
        formData.append("noticePeriod", data?.noticePeriod);
        formData.append("isWillingToRelocate", data?.isWillingToRelocate);
        formData.append("CvPdf", data?.CvPdf);
    
        fetch(`${BASEURL}/careerCreate`, {
          method: "POST",
        //   headers: {
        //     "Content-Type": "application/json"

        //   },
          body: formData,
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              setLoading(false);
              toast('Thankyou for submiting',{
                hideProgressBar: true
                });
              setAddCareerData({
                name: "",
                email: "",
                mobileNumber: "",
                CvPdf: null,
                JobRoleId: "",
                experience: "",
                currentCTC: "",
                expectedCTC: "",
                noticePeriod:"",
                isWillingToRelocate:"",
              })
            }
    
            // console.log("Response:", res);
          })
          .catch((error) => {

            setLoading(false)
            console.error("Error:", error);
            toast.error('Something Went Wrong',{
                hideProgressBar: true
            });
          });
      };

      const fetchJobRolls = ()=>{
        fetch(`${BASEURL}/jobRollGetAll`)
        .then((d)=> d.json())
        .then((res => {
            // console.log("res",res)
            if(res?.success && res?.jobRoll.length >= 1){
                setJobRolls(res?.jobRoll)
            }
        }))
      }
    useEffect(() => {
        var $ = window.jQuery;
        $('.selectpicker').selectpicker();
        animateScroll.scrollToTop();

        fetchJobRolls()
    }, []);



    const handlePrevResume = () => {
        // Preview the uploaded resume in a new tab
        if (fileContent) {
            const url = URL.createObjectURL(fileContent);
            window.open(url, '_blank');
        }
    };
    // console.log('Job Rolls',jobRolls)
    // console.log('addCareerData', addCareerData);
    return (
        <div class="page-wrapper">
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
        <section class="cta-eleven">
            <img src="assets/images/shapes/cta-shape-2-1.png" class="cta-eleven__moc-1" alt="" />
            <img src="assets/images/shapes/cta-shape-2-2.png" class="cta-eleven__moc-2" alt="" />
            <img src="assets/images/shapes/cta-shape-2-3.png" class="cta-eleven__moc-3" alt="" />
            <div class="container">
                <div>

                <h1 className='text-white'>Career</h1>
                <ul class="list-unstyled thm-breadcrumb">
                <li><a  href="javascript:void(0)" onClick={()=>handleNavigation(null,'/',props?.history)}>Home</a></li>

                    <li><span>Career</span></li>
                </ul>
                </div>
            </div>
        </section>

        <section class="contact-two">
        <div class="container">
                <div class="row justify-content-center">
                    <div class="">
                    <div class="block-title text-center">
                        <p><span>Work With Us</span></p>
                        <h3>Why Choose Akra ?</h3>
                        <div class="block-title__line"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="container">
                
            

            <div className='mb-4  whyChoseAkraContainer'>
                <img src='assets/images/resources/WhyChooseAkra.png' alt='whyChooseAkra' className='whyChoseAkra hideInMobile'/>
                <img src='assets/images/resources/Why_choose_akra_mobile_view.png' alt='whyChooseAkra' className='whyChoseAkra hideInLarge'/>
            </div>

            <section class=" mt-5 ">
            {/* <div className='container' style={{margin:'10rem 0 5rem'}}> */}
            <div className='container careerMargin'>
                <div class="block-title text-center">
                    <p><span>Career</span></p>
                    <h3>Ready To Join The Akra Family?</h3>
                    <div className='mt-4' style={{color: "#7e8aa7",fontWeight:'bold'}}>If you're excited by the prospect of joining a fast-paced, innovative company where your talent is recognized and nurtured, submit your application today! We're seeking passionate individuals who are eager to learn, grow and contribute to opur success.</div>
                    <div class="block-title__line"></div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                <img src="assets/images/resources/SalesIcon.png" class="img-fluid w-50" alt="AWS Logo" />
                            </div>
                            <h3><span class="counter">1</span></h3>
                            <p>Sales Repersentative</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                {/* <i class="far fa-heart"></i> */}
                                <img src="assets/images/resources/aws-logo.png" class="img-fluid w-50" alt="AWS Logo" />

                            </div>
                            <h3><span class="counter">1</span></h3>
                            <p>AWS Developer</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                {/* <i class="far fa-award"></i> */}
                                <img src="assets/images/resources/LaravelIcon.png" class="img-fluid w-50" alt="AWS Logo" />

                            </div>
                            <h3><span class="counter">1</span>
                            </h3>
                            <p>Laravel Developer</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="container">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <p onClick={()=> handleNavigation('career')} class="thm-btn about-one__btn cursor-pointer mt-5" style={{cursor: "pointer"}}>Apply Now <i class="fa fa-angle-double-right"></i></p>
                    </div>
                </div>
            </div> */}
        </section>
            <div class="container careerMargin">
                <div class="row justify-content-center">
                    <div class="">
                    <div class="block-title text-center">
                        <p><span>Join Us</span></p>
                        <h3>Launch Your Tech Career At Akra <br/>Where Innovation Meets Opportunity</h3>
                        <p className='text-muted mt-2'>
                        We're here to collaborate! Reach out to us today and share how your talents can contribute to our team.
                        </p>
                        <div class="block-title__line"></div>
                        </div>
                    </div>
                </div>

            </div>
           
            <div class="row">
                <div class="col-lg-5">
                    <div class="contact-one__box" style={{backgroundImage: "url(assets/images/resources/career-1-1.png)"}}>
                        <div class="contact-one__box-inner contact-one__box-inner-career">
                            <h3>Don't wait!</h3>
                            <p>Join us today and be part of unlocking the power of technology. Explore opportunites to contribute your skills and expertise to our team. </p>
                            {/* <a href="#" class="thm-btn contact-one__box-btn">Contact Us <i class="fa fa-angle-double-right"></i></a> */}
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 d-flex">
                    <div className='my-auto' >
                        <div action="inc/sendemail.php" class="contact-form-validated contact-one__form">
                            {/* <h3>Fill out the form below and we'll be in touch shortly.</h3> */}
                            <div class="row">
                            <Formik
                                initialValues={addCareerData}
                                validate={validate}
                                onSubmit={handleSubmit}
                                validateOnBlur={false}
                                validateOnChange={true}
                                >
                            {({ errors, touched }) => (
                            <Form  className='p-3'>
                                <h3>Personal Details</h3>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-user"></i>
                                            <Field type="text" placeholder="Your Full Name" name="name" className={errors.name && touched.name && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="name" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-user"></i>
                                            <Field type="email" placeholder="Your Email" name="email" className={errors.email && touched.email && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="email" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-phone"></i>
                                            <Field type="number" placeholder="Your Phone" name="mobileNumber" className={errors.mobileNumber && touched.mobileNumber && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="mobileNumber" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="contact-one__input-group" >
                                            <Field as="select" type="select" placeholder="Job role" name="JobRoleId" className={errors.JobRoleId && touched.JobRoleId && isSubmitted ? '0 form-control error' : 'form-control careerJobRole'}  style={{border:'2px solid rgba(31, 49, 81, .1)',borderRadius:'10px',minHeight:'70px'}}>
                                            <option value="" style={{textTransform:'capitalize',fontSize:'1.2rem'}} disabled selected hidden>Job Role</option>
                                                {
                                                    jobRolls?.map((jobRoll) => (
                                                        <option value={jobRoll?._id} style={{textTransform:'capitalize',fontSize:'1.2rem'}} key={jobRoll?._id}>{jobRoll?.position}</option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name="JobRoleId" component="div" className="error" />
                                        </div>
                                    </div>

                                    </div>
                                    <div className='row mt-4'>
                                    <div class="col-lg-12 font-weight-bold">
                                    <h3>Screening Queations</h3>
                                        <label  className=  'ml-2'>How many years of relevant work experience do you have?</label>
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-pencil-alt"></i>
                                            <Field type="text" name="experience" className={errors.experience && touched.experience && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="experience" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 font-weight-bold">
                                        <label className='ml-2'>What is your current CTC (in L.P.A.)?</label>
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-pencil-alt"></i>
                                            <Field type="text" name="currentCTC" className={errors.currentCTC && touched.currentCTC && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="currentCTC" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 font-weight-bold">
                                        <label className='ml-2' >What is your expected CTC (in L.P.A.)?</label>
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-pencil-alt"></i>
                                            <Field type="text" name="expectedCTC" className={errors.expectedCTC && touched.expectedCTC && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="expectedCTC" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 font-weight-bold">
                                        <label className='ml-2'>What is your notice period (in days)?</label>
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-pencil-alt"></i>
                                            <Field type="text" name="noticePeriod" className={errors.noticePeriod && touched.noticePeriod && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="noticePeriod" component="div" className="error" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 font-weight-bold">
                                        <label className='ml-2'> Are you willing to work from an office in Mumbai?</label>
                                        <div class="contact-one__input-group">
                                            <i class="contact-one__input-icon far fa-pencil-alt"></i>
                                            <Field type="text" name="isWillingToRelocate" className={errors.isWillingToRelocate && touched.isWillingToRelocate && isSubmitted ? 'form-control error' : 'form-control'} />
                                            <ErrorMessage name="isWillingToRelocate" component="div" className="error" />
                                        </div>
                                    </div>
                                    </div>

                                    <div className='row mt-4'>
                                    <div className="col-lg-12 font-weight-bold mb-4">
                                        <h3 className=''>Resume</h3>
                                        {/* <label className="ml-2">Resume</label> */}

                                        {/* <div className="file-upload-container col-lg-12"
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop}>
                                            <input type="file" accept="application/pdf" onChange={handleFileChange} />
                                            <div className="file-upload-content">
                                                <div className="file-upload-icon">
                                                <i class="fas fa-cloud-upload-alt" style={{color:'#00D9FF'}}></i>
                                                </div>
                                                {fileName && <div className="file-name">{fileName}</div>}
                                                {!fileName && <div className="file-upload-text"> <span > Drag & drop Resume file here</span> or  <span style={{color:'#00D9FF',textDecoration: "underline"}}>browse files </span></div>}
                                            </div>
                                        </div> */}
                                        <div className="file-upload-container col-lg-12"
                                            onDragOver={handleDragOver}
                                            onDrop={handleDrop}>
                                            <Field type="file" accept="application/pdf" onChange={handleFileChange} name = 'CvPdf' className={fileName && isSubmitted ? 'form-control error' : 'form-control'} />

                                            <div className="file-upload-content">
                                                <div className="file-upload-icon">
                                                <i class="fas fa-cloud-upload-alt" style={{color:'#00D9FF'}}></i>
                                                </div>
                                                {fileName && <div className="file-name">{fileName}</div>}
                                                {!fileName && <div className="file-upload-text"> <span > Drag & drop Resume file here</span> or  <span style={{color:'#00D9FF',textDecoration: "underline"}}>browse files </span></div>}
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                !fileName && !fileContent && isSubmitted ?
                                                <ErrorMessage name="CvPdf" component="div" className="error" />:""
                                            }
                                        </div>

                                            {fileContent && (
                                                <div className='resumePrevContainer' >
                                                    <p className='thm-btn text-white' onClick={handlePrevResume} > Preview Resume
                                                    <i className="far fa-file-pdf pl-1"></i>
                                                    </p>
                                        </div>
                                            )}
                                    </div>
                                    </div>

                                    <div class="col-lg-12" style={{zIndex: '99999'}}>
                                        <button type='submit' class="thm-btn mt-2 text-white" style={{cursor:'pointer',outline:'none'}}>{loading ? 'sending...': 'Send Message'} <i class="fa fa-angle-double-right"></i></button>

                                        {/* <p onClick={handleSubmit} class="thm-btn mt-2 text-white" style={{cursor:'pointer'}}> {loading ? 'sending...': 'Send Message'} <i class="fa fa-angle-double-right" /></p> */}
                                    </div>
                                {/* </div> */}
                            </Form>
                            )}
                            </Formik>
                            </div>
                        </div>
                        <div class="result"></div>
                    </div>
                </div>
            </div>
            </div>
        </section>
        </div>
    )
}

export default withRouter(Career);
