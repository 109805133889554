import React from 'react'
import { withRouter } from "react-router";
import { Link, Element  } from 'react-scroll';

function Career(props){

    const handleNavigation = (actionLink) => {
        props.history.push(actionLink);
    }

    return (
        <Element name="career-section">
            <section class="funfact-one mt-5">
            <div className='container'>
                <div class="block-title text-center">
                    <p><span>Career</span></p>
                    <h3>Ready To Join Akra Family?</h3>
                    <div className='mt-4' style={{color: "#7e8aa7",fontWeight:'bold'}}>If you're excited by the prospect of joining a fast-paced, innovative company where your talent is recognized and nurtured, submit your application today! We're seeking passionate individuals who are eager to learn, grow and contribute to opur success.</div>
                    <div class="block-title__line"></div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                <img src="assets/images/resources/SalesIcon.png" class="img-fluid w-50" alt="AWS Logo" />
                            </div>
                            <h3><span class="counter">1</span></h3>
                            <p>Sales Repersentative</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                {/* <i class="far fa-heart"></i> */}
                                <img src="assets/images/resources/aws-logo.png" class="img-fluid w-50" alt="AWS Logo" />

                            </div>
                            <h3><span class="counter">1</span></h3>
                            <p>AWS Developer</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="funfact-one__single">
                            <div class="funfact-one__icon">
                                {/* <i class="far fa-award"></i> */}
                                <img src="assets/images/resources/LaravelIcon.png" class="img-fluid w-50" alt="AWS Logo" />

                            </div>
                            <h3><span class="counter">1</span>
                            </h3>
                            <p>Laravel Developer</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <p onClick={()=> handleNavigation('career')} class="thm-btn about-one__btn cursor-pointer mt-5" style={{cursor: "pointer"}}>Apply Now <i class="fa fa-angle-double-right"></i></p>
                    </div>
                </div>
            </div>
        </section>
        </Element>
    )
}

export default withRouter(Career);
