import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import {animateScroll} from 'react-scroll'
import bannerImage from '../../assets/images/backgrounds/banner-bg-1-2.png';
import bannerImage1 from '../../assets/resources/banner-1-2.png';
import { BASEURL } from '../../constant/constant';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Ebooks = (props) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userDetails, setUserDetails] = useState({
        name: "",
        email: "",
        phoneNumber: ""
    });

    const validate = (values) => {
        const errors = {};
        
        setIsSubmitted(true);
        if (!values.name) {
          errors.name = 'Name field is required';
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = 'Phone number field is required';
        }else if(!/^[0-9]{10}$/i.test(values.phoneNumber)){
            errors.phoneNumber = 'Invalid phone number';
        }

        if (!values.email) {
            errors.email = 'Email field is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    const handleSubmit = (data) =>{
        setUserDetails(data)
        handleUserDeatils(data)
    }

    const handleUserDeatils = (contactData) => {
        let vObj = contactData;
        vObj.phoneNumber = vObj.phoneNumber+""
        fetch(`${BASEURL}/downloadpdfAdd`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify(contactData),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.success) {
                    toast('Thankyou for submiting',{
                        hideProgressBar: true
                    });
                    document.getElementById('close').click();
                    const pdfUrl = "Mastering-the-retail-landscape.pdf";
                    const link = document.createElement("a");
                    link.href = pdfUrl;
                    link.download = "Mastering-the-retail-landscape.pdf";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setUserDetails({
                        name: "",
                        email: "",
                        phoneNumber: "",
                    })
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                alert("Something Went Wrong");
            });
        };

    useEffect(() => {
        // Scroll to the top of the page on first rendering
        animateScroll.scrollToTop();
    }, []);

    return (
        <section class="banner-one" style={{top: "-1px",marginLeft: "-2px",backgroundSize: 'cover',backgroundPosition: "center", backgroundImage: `url(${bannerImage})`}}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <img src={bannerImage1} alt="" class="banner-one__moc ebook-img" />
            <div class="container-fluid ebook-content" style={{ paddingTop: "110px",paddingBottom: "140px"}}>
                <div class="">
                    <div class="col-lg-7">
                        <div class="banner-one__content">
                            <button style={{zIndex: 99}} data-toggle="modal" data-target="#exampleModal" class="thm-btn banner-one__btn gradient-btn-2">DOWNLOAD YOUR FREE E-BOOK NOW</button>
                            <h3>
                                <div style={{fontSize: "3rem",letterSpacing: "0.1rem",lineHeight: "3.5rem"}}>
                                    <div class='d-flex'>
                                        <div className='gradient-text'>MASTERING THE <br /> RETAIL LANDSCAPE</div>  
                                    </div> 
                                </div>
                            </h3>
                            <h3 className='gradient-text mt-3' style={{fontSize: "1.2rem",letterSpacing: "0.1rem",lineHeight: "2rem"}}>HOW TECHNOLOGY CAN TRANSFORM YOUR BUSINESS!!!</h3>
                            <p style={{fontSize: "0.9rem",fontWeight:"500",marginBottom: 0}}>
                            In this eBook, we explore how technology is reshaping the retail landscape. From streamlining operations to driving sales innovation, technology offers endless possibilities for retailers of all sizes.</p>
                            <p style={{fontSize: "0.9rem",fontWeight:"500",marginBottom: 0,marginTop: "15px"}}>
                            Today's retail industry faces challenges and opportunities fueled by rapid technological advancements and changing consumer behaviors. By embracing technology, retailers can unlock new growth avenues, personalize customer experiences, and stay ahead in a competitive market.
                            </p>
                            <p style={{fontSize: "0.9rem",fontWeight:"500",marginBottom: "3rem",marginTop: "15px"}}>
                            Join us on this journey to discover practical insights and actionable strategies for mastering the retail landscape. Let's explore how technology can empower your business to thrive in the ever-evolving world of retail!
                            </p>
                            <button style={{zIndex: 99}} data-toggle="modal" data-target="#exampleModal" class="thm-btn banner-one__btn mb-5 gradient-btn-1">DOWNLOAD YOUR FREE E-BOOK NOW</button>
                            {/* <a id='downloadLink' download="Ebook.pdf" style={{display: 'none'}} href='https://exploringjs.com/impatient-js/downloads/impatient-js-preview-book.pdf'></a> */}
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Details</h5>
                                    <button id='close' type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                <Formik
                                        initialValues={userDetails}
                                        validate={validate}
                                        onSubmit={handleSubmit}
                                        validateOnBlur={false}
                                        validateOnChange={true}
                                        >
                                    {({ errors, touched }) => (
                                    <Form>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i style={{top: "12px",right: "10px"}} class="contact-one__input-icon far fa-user"></i>
                                                    <Field type="text" placeholder="Your Full Name" name="name" className={errors.name && touched.name && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="name" component="div" className="error" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i style={{top: "12px",right: "10px"}} class="contact-one__input-icon far fa-envelope"></i>
                                                    <Field type="email" placeholder="Your Email" name="email" className={errors.email && touched.email && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="email" component="div" className="error" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="contact-one__input-group">
                                                    <i style={{top: "12px",right: "10px"}} class="contact-one__input-icon far fa-phone"></i>
                                                    <Field type="number" placeholder="Your Phone" name="phoneNumber" className={errors.phoneNumber && touched.phoneNumber && isSubmitted ? 'form-control error' : 'form-control'} />
                                                    <ErrorMessage name="phoneNumber" component="div" className="error" />
                                                </div>
                                            </div>
                                            {/* <div class="col-lg-12" style={{zIndex: '99999'}}> */}
                                            <div class="col-lg-12" style={{zIndex: '1'}}>
                                                <button type='submit' class="thm-btn mt-2 text-white" style={{cursor:'pointer',float: "right", padding:" 4px 30px"}}>Save<i class="fa fa-angle-double-right"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                    )}
                                    </Formik>
                                </div>
                                {/* <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type='submit' class="btn btn-primary">Save</button>
                                </div> */}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withRouter(Ebooks);
