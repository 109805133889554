
const handleNavigation = (routeType,actionLink,history) => {

        if (routeType == 'newTab') {
            window.open(actionLink, '_blank');
        } else {
            history.push(actionLink);
        }
}

export default handleNavigation