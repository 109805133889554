import React from 'react'
import { withRouter } from "react-router";
import { Link, Element  } from 'react-scroll';

function AboutUs(){
    return (
        <Element name="about-section">
            <section class="about-one">
                <div class="container">
                    <img src="assets/images/resources/cta-1-1.png" alt="" class="about-one__moc hideInMobile" />
                    <div class="row justify-content-end">
                        <div class="col-lg-6">
                            <div class="about-one__content">
                                <div class="block-title text-left">
                                    <p><span>About Us</span></p>
                                    <img src="assets/images/resources/cta-1-1.png" alt="" class="about-one__moc hideInLarge" />

                                    <h3>Who We Are </h3>
                                    <div class="block-title__line"></div>
                                </div>
                                <p>Akra is a leading provider of IT solutions, specializing in business solutions. We are committed to providing our clients with the highest level of support and satisfaction. We are a team of passionate and experienced IT professionals who are dedicated to helping businesses achieve their goals.</p>
                                <p>At Akra, we understand that technology is the backbone of modern business success. That's why we offer a comprehensive suite of IT services designed to help you achieve your unique goals and objectives. Whether you're looking to boost your online presence, secure your data, or streamline your operations, our team of experts has the knowledge and experience to deliver custom solutions that fit your specific needs.</p>
                                {/* <h4>We Are Always With You</h4>
                                <p>But we must be dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                    laboris nisi ut aliquip exea commodo consequat</p> */}
                                {/* <a href="$" class="thm-btn about-one__btn">Read More <i class="fa fa-angle-double-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Element>
    )
}

export default withRouter(AboutUs);
