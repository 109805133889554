import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Text } from '../../../../components';
import axios from "axios";
import { Link, animateScroll as scroll } from "react-scroll";


const Footer = (props) => {
    console.log('props.history',props.history.location.pathname);
    const handleNavigation = (path) => {
        props.history.push(path);
    }
  return (
    <React.Fragment>
    {
        props.history.location.pathname !== '/ebook'? <footer class="site-footer-four">
        <div class="site-footer-four__bubble-1"></div>
        <div class="site-footer-four__bubble-2"></div>
        <div class="site-footer-four__bubble-3"></div>
        <div class="site-footer-four__bubble-4"></div>
        <div class="site-footer-four__mc-wrap">
        </div>
        <div class="site-footer-four__upper">
            <div class="container">
                {/* <div class="site-footer-four__upper-sep"></div> */}
                <div class="row">
                    <div class="col-lg-5">
                        <div class="footer-widget footer-widget__about">
                            <a href="index.html">
                                <img style={{width: '85px'}} src="assets/images/logo-full-light.png" alt="" />
                            </a>
                            <p>Akra is a leading provider of IT solutions, specializing in business solutions. We are committed to providing our clients with the highest level of support and satisfaction. </p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                    {props.location.pathname == '/' && (
                        <div class="footer-widget footer-widget__links">
                            <h3 class="footer-widget__title">Company</h3>
                            <ul class="list-unstyled">
                                <li>
                                    <Link 
                                        to="about-section" 
                                        spy={false} 
                                        smooth={true} 
                                        offset={-120} 
                                        duration={1000} 
                                    >
                                        About Us
                                    </Link>
                                </li>
                                <li><a href="javascript:void(0)" onClick={()=> handleNavigation('career')}>Career</a></li>
                                <li>
                                    <Link 
                                        to="contact-section" 
                                        spy={false} 
                                        smooth={true} 
                                        offset={-120} 
                                        duration={1000} 
                                    >
                                        Contact Us
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="services-section" 
                                        spy={false} 
                                        smooth={true} 
                                        offset={-120} 
                                        duration={1000} 
                                    >
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to="why-choose-section" 
                                        spy={false} 
                                        smooth={true} 
                                        offset={-120} 
                                        duration={1000} 
                                    >
                                        Why Choose Us
                                    </Link>
                                </li>
                            </ul>
                        </div>)}
                    </div>
                    <div class="col-lg-3">
                        <div class="footer-widget">
                            <div class="footer-widget__social">
                                <p>Follow Us On Social Media</p>
                            </div>
                            <div class="footer-widget__menu">
                            </div>
                            <div class="footer-widget__social">
                                {/* <a href="#"><i class="fab fa-facebook-f"></i></a> */}
                                {/* <a href="#"><i class="fab fa-twitter"></i></a> */}
                                {/* <a href="#"><i class="fab fa-google-plus-g"></i></a> */}
                                <a href="https://www.linkedin.com/company/akra-teq/" target="_blank" rel="noreferrer"><i class="fab fa-linkedin-in"></i></a>
                                {/* <a href="#"><i class="fab fa-behance"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="site-footer-four__bottom">
            <div class="container text-center">
                <div class="site-footer-four__bottom-sep"></div>
                <p>Copy@2024 <a href="#">Akra</a>. All Right Reserved.</p>
            </div>
        </div>
</footer> : ""
    }
    </React.Fragment>
  );
};

export default withRouter(Footer);
